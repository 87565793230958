import Nav from 'react-bootstrap/Nav';
import React from 'react';
import { Link } from "react-router-dom";


const Header = () => {
  return (
    <Nav
        activeKey="/"
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        style={{backgroundColor:"white"}}
    >
        <Nav.Item>
            <Nav.Link style={{color:"#004aad"}}  as={Link} to="/" >Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link style={{color:"#004aad"}}  as={Link} to="/rate" >Rate</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link style={{color:"#004aad"}}  as={Link} to="/viewtrip">View Trip Experience</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link style={{color:"#004aad"}}  as={Link} to="/aboutus">About Us</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link style={{color:"#004aad"}}  as={Link} to="/privacy">Privacy </Nav.Link>
        </Nav.Item>
        
  </Nav>
  )
}

export default Header


