import React from 'react';
import Header from '../../Header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchAirports from '../ViewTripPage/SearchAirports';
import { Helmet } from 'react-helmet-async';
import Ads from "../Ad";
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
const ViewTrip = () => {
  return (
    <>
    <Helmet >
    <title>Plan A Trip </title>
    <link rel="notImportant" href="https://ratemyairport.ca/viewtrip/" />
    <meta name="description"   content="Rate Airports, plan a trip and see what to expect on your travel." />
    <link rel="canonical" href="https://ratemyairport.ca/viewtrip/" />
    <meta property="og:title" content="rate Airports,"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://ratemyairport.ca/viewtrip/"/>
 
  </Helmet> 
    <Container fluid style={{
        
      // backgroundColor:"#e1f7fd"
      }}>
      <Row>
          <Header />
      </Row>
      <Row>
        <SearchAirports /> <br />
       
      </Row>
  
  </Container>
  </>
  )
}

export default ViewTrip