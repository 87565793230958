import React from 'react'
import Select from 'react-select'
import WindowedSelect from "react-windowed-select";
import {createFilter } from 'react-windowed-select';
import { useEffect, useState, useRef } from 'react'
import AsyncSelect from "react-select/async";
import { createClient } from '@supabase/supabase-js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Airports from './Airports';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import AirportName from "./AirportName";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aiportsDB from './airportsDb';
import Spinner from 'react-bootstrap/Spinner';
import CurrentStats from './CurrentStats';
import Privacy from '../PrivacyPage/Privacy';

const customFilter = createFilter({ ignoreAccents: false });

const AirportStats = () => {
 

  const [airports, setAirports] = useState([]) 
 
  const [error, setError] = useState("");
  const [filteredAirport, setFilteredAirport] = useState("")
  const searchRef = useRef(null);
  const [alpha,setAlpha] = useState(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M","N","O", "P", "Q", "R","S","T", "U","V","W","X","Y","Z"])
  const [currentAlpha, setCurrentAlpha] = useState("");
  const [currentICAO, setCurrentICAO] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(()=>{


    setOptions(aiportsDB)
    // const supabaseUrl = process.env.REACT_APP_URL
    // const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
    // const supabase = createClient(supabaseUrl, supabaseKey)

  
    
    // const fetchAirports = async ()=>{
      
    //   const {data, error} = await supabase.from('Airports')
    //   .select()
    //   if(error){
    //     setError("Could not fetch the airports");
    //     setAirports([]);
    //   }
    //   if(data){
        
    //     const filteredAirports = data.filter((el) => el.name.startsWith("A"));
    //     setFilteredAirport(filteredAirports)
    //     setAirports(data);
    //     let tempOptions = data.map((el)=>{
    //       let label = `${el.ICAO}, ${el.name}`
    //      return {value: el.ICAO, label:label }
    //     });
        
    //     setOptions(tempOptions)
    //     setError("");
    //   }
      
    // }
    // fetchAirports();

  }, [])

  const fetchAirports = async ()=>{
   
    const supabaseUrl = process.env.REACT_APP_URL
    const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
    const supabase = createClient(supabaseUrl, supabaseKey)
    const {data, error} = await supabase.from('Airports')
    .select()
    if(error){
      setError("Could not fetch the airports");
      setAirports([]);
    }
    if(data){
      
      if(currentAlpha){
    
        const filteredAirports = data.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).filter((el) => el.name.startsWith(currentAlpha));
        setFilteredAirport(filteredAirports)
      } else {

       if(selectedAirport){
        let airportlist = data.filter((el)=>{ return el.ICAO === selectedAirport.value});
        if(airportlist.length >0){
          setFilteredAirport(airportlist)
        } else {
          setFilteredAirport([])
        }
       }
        // const filteredAirports = data.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).filter((el) => el.ICAO.startsWith(currentICAO.toUpperCase()));
        // console.log(filteredAirports)

        // if(filteredAirports.length > 0){
        //   setFilteredAirport(filteredAirports)
        // } else {
        //   setFilteredAirport([])
        // }
      }

      setAirports(data);
      setError("");
    }
    
  }
  const filterArray = (airport)=> {
    setCurrentAlpha("")
    if(airport === ""){
      setFilteredAirport([])
    } else {
        const filteredAirports = airports.filter((el) => el.ICAO.startsWith(airport.toUpperCase()));
        if(filteredAirports.length > 0){
          setFilteredAirport(filteredAirports)
        } else {
          setFilteredAirport([])
        }
      }
  }

  const filterByName = (airport) =>{
    setCurrentICAO("")
    setSelectedAirport(null)
    if(airport === ""){
      setFilteredAirport([])
    } else {
        const filteredAirports = airports.filter((el) => el.name.startsWith(airport.toUpperCase()));
        if(filteredAirports.length > 0){
          setFilteredAirport(filteredAirports)
        } else {
          setFilteredAirport([])
        }
      }
  }

  const settheAlpha = (alpha)=>{
    setCurrentAlpha(alpha);
  }


  
  const getAirports = async(airport) =>{
    const supabaseUrl = process.env.REACT_APP_URL
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
  const supabase = createClient(supabaseUrl, supabaseKey)
 
    
    const {data, error} = await supabase.from('Airports')
    .select()
    .eq("ICAO", airport)
    .single();
    
    if(data){
      setLoading(false)
      return setFilteredAirport(data)

    }  
    setLoading(false)
   
  }

  const handleChange = (airport)=>{
   
   if(airport){
    setLoading(true)
     setCurrentAlpha("")
     setSelectedAirport(airport)
     getAirports(airport.value)
   } else {
    setSelectedAirport(airport)
    setFilteredAirport("")
   }
    // if(airport){
    //  let airportlist = airports.filter((el)=>{ return el.ICAO === airport.value});
    //  if(airportlist.length > 0){
    //    setFilteredAirport(airportlist)
    //  }
    // } else {
    //   setFilteredAirport([])
    // }
  // //  selectedAirport({label: airport[0], value: airport[0].})
  //  console.log(airport)

  }
  
  return (
    <div 
        style={{
                display:"flex",
                alignItems:"center", 
                justifyContent:"center", 
                flexDirection:"column",
                padding:"10px",
                gap:"10px",
                // backgroundColor:"#b8e2fc"
              }}
                 >
                  <div 
                  style={{
                            width:"100%", 
                            padding:"10px", 
                            backgroundColor:"white",
                            display:"flex",
                            justifyContent:"center",
                      
                        }}>
                           <h2> Rate  Airport</h2>
                           <br />
                          
                        </div>
                 
                  <div style={{backgroundColor:"white", zIndex:"2", padding:"10px", width:"100%", padding:"50px", boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>

                  
                <h1 style={{fontSize:"1em"}}> Search Airports(by City, name or ICAO Code) </h1>
                <WindowedSelect
                  options={options}
                  onChange={handleChange}
                  value={selectedAirport}
                  placeholder ={"Enter Airport Name or ICAO code"}
                  isClearable={handleChange}
                  filterOption={customFilter}
                />
                {/* <Select 
                   filterOption={false}
                      value={selectedAirport}
                      isSearchable ={true}
                      clearValue={setSelectedAirport}
                      placeholder ={"Enter Airport Name or ICAO code"}
                      onChange={handleChange}
                      isClearable={true}
                      // onChange={handleChange}
                      isLoading={options.length > 0 ? false : true}
                      options={options}
                      /> */}
                 
                  {/* <Form style={{display:"flex", flexDirection:"column"}}>
                      <Form.Group className="mb-3" ref={searchRef} >
                        <Form.Control value={currentICAO} onClick={(e)=>setCurrentAlpha("")}  onChange={(e)=>{ setCurrentICAO(e.target.value); filterArray(e.target.value)}} type="text" placeholder="Enter Airport Code" />
                        <Form.Text className="text-muted">
                        </Form.Text>                        
                      </Form.Group>
                    </Form>
                   */}
                    </div>
                     
                       
                        {/* <Row>
                          <Col xs={12} md={6}>
                          <h2 style={{fontSize:"2em"}}>Search By Name</h2>
                          
                          </Col>
                          <Col xs={12} md={6}>
                         
                         
                       {alpha.map((el,i)=>(
                           <AirportName key={i} 
                               alpha={el} 
                               filterByName={filterByName} 
                               setCurrentAlpha={settheAlpha} 
                               currentAlpha={currentAlpha}  
                               />
                          ))}
                     
                          </Col>
                       
                        </Row> */}
                 

                        <div style={{width:"100%", 
                            padding:"10px", 
                            backgroundColor:"white",
                            display:"flex",
                            justifyContent:"center",
                            flexWrap:"wrap",
                      
                        }}>
                          {/* <div>
                              <h2 style={{fontSize:"2em"}}>Search By Name</h2>
                          </div>
                          <div style={{width:"100%", 
                             
                              backgroundColor:"white",
                              display:"flex",
                              justifyContent:"center",
                              flexDirection:"row",
                              flexWrap:"wrap",
                             
                      
                        }}>
                          
                        {alpha.map((el,i)=>(
                            <AirportName key={i} 
                                alpha={el} 
                                filterByName={filterByName} 
                                setCurrentAlpha={settheAlpha} 
                                currentAlpha={currentAlpha}  
                                ></AirportName>
                           ))}
                        </div> */}
                   
                          
                        </div>
                              {loading && <Spinner />}
                             {filteredAirport && 
                             <Airports key={filteredAirport.ICAO}                                 
                                airport={filteredAirport} 
                                fetchAirports={fetchAirports}
                                />} 

                                 {filteredAirport &&
                                <>
                                
                                   <h2>Current Stats</h2>
                                    <CurrentStats                                
                                      airport={filteredAirport} 
                                      fetchAirports={fetchAirports}
                                  />
                                </>
                              } 
                            
                              {/* {filteredAirport.map((el, i)=>( */}
                              
                         
                        
      
      </div>
  )
}

export default AirportStats