import React from 'react'

const RateMyAirport = () => {
  return (
    <div>
        <div style={{display:"flex", justifyContent:"center", fontFamily:"cursive", marginTop:"10px"}}>
            <h1>  Rate My Airport</h1>          
        </div>

        <div style={{display:"flex", justifyContent:"center", fontFamily:"cursive", marginTop:"10px"}}>
            <p>
                Travelling can be exciting, a good airport can make or break a trip. but a bad one....
                <br /> When you Rate, you help other travellers know what to expect. Thank You for your input.
            </p>        
        </div>

      
        
            


    </div>
  )
}

export default RateMyAirport