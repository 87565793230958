import React from 'react';
import Select from 'react-select';
import { AiOutlineArrowDown } from "react-icons/ai";
import { IoAirplane } from "react-icons/io5";
import {TbArrowsLeftRight} from "react-icons/tb";
import {useState, useEffect} from "react";
import { createClient } from '@supabase/supabase-js';
import { Button } from 'react-bootstrap';
import {BiErrorCircle} from 'react-icons/bi';
import {FaPlaneDeparture} from 'react-icons/fa';
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { BsFillEmojiAngryFill } from "react-icons/bs";
import { BsEmojiNeutralFill } from "react-icons/bs";
import {BsEmojiFrown} from "react-icons/bs";
import airportsDB from '../RatePage.js/airportsDb';
import WindowedSelect from "react-windowed-select";
import {createFilter } from 'react-windowed-select';
import Spinner from 'react-bootstrap/Spinner';
// import aiportsDB from './airportsDb'
const customFilter = createFilter({ ignoreAccents: false });

const SearchAirports = () => {
    const [airports, setAirports] = useState([]);
    const [error, setError] = useState("");
    const [optionsOrigin, setOptionsOrigin] = useState([]);
    const [optionsDestination, setDestinationOptions] = useState([]);
    const [optionsmultiCityOrigin, setMultiCityOriginOptions] = useState([]);
    const [optionsmultiCityConnection, setMultiCityConnectionOptions] = useState([]);
    const [optionsmultiCityDestination, setMultiCityDestinationOptions] = useState([]);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [originMulti, setOriginMulti] = useState(null);
    const [connetingMulti, setConnetingMulti] = useState(null);
    const [destinationMulti, setDestinationMulti] = useState(null);

    const [originError, setOriginError] = useState("");
    const [destinationError, setDestinationError] = useState("");
    const [originMultiError, setOriginMultiError] = useState("");
    const [connectingMultiError, setconnectinMultiError] = useState("");
    const [destinationMultiError, setDestinationMultiError] = useState("");
    const [originAirport, setOriginAirport] = useState([]);
    const [destinationAirport, setDestinationAirport] = useState([])
    const [connectingAirport, setConnectingAirport] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
 
        const supabaseUrl = process.env.REACT_APP_URL
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
        const supabase = createClient(supabaseUrl, supabaseKey)
    
      
        
        // const fetchAirports = async ()=>{
          
        //   const {data, error} = await supabase.from('Airports')
        //   .select()
        //   if(error){
        //     // setError("Could not fetch the airports");
        //     setAirports([]);
        //   }
        //   if(data){
            
        //     const filteredAirports = data.filter((el) => el.name.startsWith("A"));
        //     // setFilteredAirport(filteredAirports)
        //     setAirports(data);
        //     let tempOptions = data.map((el)=>{
        //       let label = `${el.ICAO}, ${el.name}`
        //      return {value: el.ICAO, label:label }
        //     });
            
        //   }
          
        // }
        setOptionsOrigin(airportsDB);
        setDestinationOptions(airportsDB);
        setMultiCityOriginOptions(airportsDB);
        setMultiCityConnectionOptions(airportsDB);
        setMultiCityDestinationOptions(airportsDB);
        setError("");
        // fetchAirports();
    
      }, [])


      const handleChangeSingle = (option)=>{            
            setOrigin(option);
            setDestinationAirport("");
            setConnectingAirport("")
            setOriginAirport("")
            if(originError === "You Cannot Leave and arrive from the same place?"){
              setOriginError("")
            } else {
              setOriginError("");
              setOriginMulti(null);
              setConnetingMulti(null);
              setDestinationMulti(null);
              setOriginMultiError(null);
              setconnectinMultiError(null)
              setDestinationMultiError(null);
            }


      }

      const handleChangesDestination = (option)=>{
        setDestination(option)
        setDestinationAirport("")
        setOriginAirport("")
        setConnectingAirport("")
        if(originError === "You Cannot Leave and arrive from the same place?"){
          setOriginError("")
        }
        setDestinationError("")
        setOriginMulti(null);
        setConnetingMulti(null);
        setDestinationMulti(null);
        setOriginMultiError(null);
        setconnectinMultiError(null)
        setDestinationMultiError(null);
      }

      const handleChangesOrginMultiple = (option) =>{
        setDestinationAirport("")
        setConnectingAirport("")
        setOriginAirport("")
        setOriginMulti(option);
        setOriginError("")
        setDestinationError("")
        setOrigin("")
        setDestination("")
        setOriginMultiError(null);
    

      }

      const handleChangesConntionMultiple = (option) =>{
        setDestinationAirport("")
        setConnectingAirport("")
        setOriginAirport("")
        setConnetingMulti(option);
        setOrigin("")
        setDestination("")
        setOriginError("")
        setDestinationError("")
        setconnectinMultiError(null)
 

      }

      const insertAirport = async(option)=>{
        const supabaseUrl = process.env.REACT_APP_URL
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
        const supabase = createClient(supabaseUrl, supabaseKey)
            const {data, error} = await supabase.from('Airports')
          .insert(option)
          .single()

          if(data){
            return data
          }
      }
      const handleChangesDestinationMultiple = (option) =>{
        setDestinationAirport("")
        setConnectingAirport("")
        setOriginAirport("")
        setDestinationMulti(option)
        setOrigin("");
        setDestination("");
        setOriginError("")
        setDestinationError("")
        setDestinationMultiError(null);
      }

      const addAirport = async()=>{
        const supabaseUrl = process.env.REACT_APP_URL
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
        const supabase = createClient(supabaseUrl, supabaseKey)
    
        for(let i =0; i < airportsDB.length ; i++) {
          let stuff = await insertAirport(airportsDB[i]);
          console.log(i)

        }
       
          // const {data, error} = await supabase.from('Airports')
          // .insert({ICAO:"test", name:"test", Country:"test"})

          // if(data){
          //   console.log(data)
          // }

          
      }

      const getAirports = async(airport) =>{
        const supabaseUrl = process.env.REACT_APP_URL
      const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
      const supabase = createClient(supabaseUrl, supabaseKey)
     
        
        const {data, error} = await supabase.from('Airports')
        .select()
        .eq("ICAO", airport.value)
        .single();
        if(data){
          return data
        }
      }

      const getAirportsMultiple = async(airport) =>{
        const supabaseUrl = process.env.REACT_APP_URL
      const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
      const supabase = createClient(supabaseUrl, supabaseKey)
     
        
        const {data, error} = await supabase.from('Airports')
        .select()
        .eq("ICAO", airport.value)
        .single();
        if(data){
          return data
        }
      }

      const submitSingle = () =>{
        setLoading(true)
        if(!origin){
            setOriginError("Please select airport you are Departing From")
        }
        if(!destination){
            setDestinationError("Please select airport you are Arriving at")
        }

        if(origin.value === destination.value){
          setOriginError("You Cannot Leave and arrive from the same place?")
          setLoading(false)
        }

        if(origin && destination && origin.value !== destination.value){
          const getAirport = async() =>{
            let og = await getAirports(origin)
            let dg =  await getAirports(destination)
            setOriginAirport(og);
            setDestinationAirport(dg);
            setLoading(false)
           
          }
          getAirport();
        }
      }

      const submitMulti = () =>{
       setLoading(true)
        let submit = true
        if(!originMulti){
          setOriginMultiError("Please select Departure Airport ");
          submit = false
        }
        if(!connetingMulti){
          setconnectinMultiError("Please select Connecting Airport");
          submit = false
        }
        if(!destinationMulti){
          submit = false
          setDestinationMultiError("Please select Destination Airport");
        }
        if(originMulti.value === connetingMulti.value){
          submit = false
          setconnectinMultiError("Origin and connecting airports cannot be the same");
          setLoading(false)
        }

        if(destinationMulti.value === connetingMulti.value){
          submit = false
          setDestinationMultiError("Arrival and connecting airports cannot be the same");
          setLoading(false)
        }

        
        if(originMulti.value === destinationMulti.value){
          submit = false
          setDestinationMultiError("Origin and Arival airports cannot be the same");
          setLoading(false)
        }


        
        if(submit){
         
        
              const getAirports = async() =>{
                let og = await getAirportsMultiple(originMulti)
                let dg =  await getAirportsMultiple(destinationMulti)
                let cn = await getAirportsMultiple(connetingMulti)
                setOriginAirport(og);
                setDestinationAirport(dg);
                setConnectingAirport(cn)
                setLoading(false)
              
              }

              getAirports();
          }
      }



  return (
    <div>
        <div 
            style={{
                    backgroundColor:"white", 
                    zIndex:"2", 
                    padding:"10px",
                    width:"100%", 
                    padding:"10px", 
                    boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            }}
         >
          {/* <Button onClick={(e)=>addAirport()}>Add Aiport</Button> */}
        {/* <div 
            style={{
                display:"flex", 
                justifyContent:"center",
                alignContent:"center",
                flexDirection:"column",
                textAlign:"center",
                width:"100%", 
                padding:"10px", 
                backgroundColor:"white",
            }}
         > */}
         <div style={{display:"flex", justifyContent:"center", flexDirection:"row"}}>
            <h1>Planning A Trip?</h1> 
         </div>
          <div style={{display:"flex", justifyContent:"center", flexDirection:"row"}}>
            <p>Start By Typing The Airports you Are Flying Through</p>
         </div>
            <div
              style={{display:"flex", 
              justifyContent:"center", 
              textAlign:"center",
              flexDirection:"column",   
              width:"100%"
            }}>
                    <h2>Direct Flight</h2> <br />
                <div style={{display:"flex", justifyContent:"center", flexDirection:"row", }}>
                  <div style={{width:"40%"}}>
                        <WindowedSelect                          
                            value={origin}
                            placeholder ={"Origin"}                          
                            filterOption={customFilter}
                            onChange={handleChangeSingle}
                            options={optionsOrigin}
                      />
                            {/* <Select 
                              value={origin}
                          
                            //   isSearchable ={true}
                            //   clearValue={setSelectedAirport}
                       
                            placeholder ={"Origin"}
                            //   onChange={handleChange}
                            //   isClearable={true}
                             onChange={handleChangeSingle}
                              options={optionsOrigin}
                            /> */}
                  </div>
                            {/* <AiOutlineArrowDown size={10}/> */}
                            <div style={{display:"flex", flexDirection:"center", alignItems:"center"}}>

                                <TbArrowsLeftRight size={20}/>
                            </div>
                            <div style={{width:"40%"}}> 
                            <WindowedSelect                          
                                  value={destination}
                                  placeholder ={"Destination"}                         
                                  filterOption={customFilter}
                                  onChange={handleChangesDestination}
                                  options={optionsDestination}
                            />
                                {/* <Select 
                                  value={destination}
                                //   isSearchable ={true}
                                //   clearValue={setSelectedAirport}
                                placeholder ={"Destination"}
                                //   onChange={handleChange}
                                //   isClearable={true}
                                  onChange={handleChangesDestination}
                                  options={optionsDestination}
                                /> */}
                            </div>
                            <br />
                            {/* <IoAirplane size={20} style = {{transform: 'rotate(90deg)' }}/>  */}
                            
                </div>
                
                <span>
                                {originError && (<>
                                
                                  <BiErrorCircle size={20} style={{color:"darkred"}}/>  {originError} <br />
                                </>)}
                                {destinationError && (<>
                                
                                <BiErrorCircle size={20} style={{color:"darkred"}}/>  {destinationError} <br />
                              </>)}
                            </span>
                <br />
                <div style={{display:"flex", justifyContent:"center"}}>
                            <Button onClick = {()=>{submitSingle()}}style={{maxWidth:"20%"}}>Search</Button>
                            <br />
                </div>
            </div>
        <br />
        <div style={{display:"flex", justifyContent:"center", textAlign:"center",flexDirection:"column"}}>
                    <h2>Connecting</h2> <br />
                <div style={{display:"flex", justifyContent:"center", flexDirection:"row", }}>
                <div style={{width:"40%"}}> 
                           <WindowedSelect                          
                                  value={originMulti}
                                  placeholder ={"Origin"}                         
                                  filterOption={customFilter}
                                  onChange={handleChangesOrginMultiple}
                                  options={optionsmultiCityOrigin}
                                />
                             {/* <Select 
                              value={originMulti}
                            //   isSearchable ={true}
                            //   clearValue={setSelectedAirport}
                            placeholder ={"Origin"}
                            //   onChange={handleChange}
                            //   isClearable={true}
                              onChange={handleChangesOrginMultiple}
                              options={optionsmultiCityOrigin}
                            /> */}
                
             
                </div>
                            {/* <AiOutlineArrowDown size={10}/> */}
                            <div style={{display:"flex", flexDirection:"center", alignItems:"center", }}>

                                <TbArrowsLeftRight size={20}/>
                            </div>
                            <div style={{width:"40%"}}> 
                            <WindowedSelect                          
                                  value={connetingMulti}
                                  placeholder ={"Connecting"}                         
                                  filterOption={customFilter}
                                  onChange={handleChangesConntionMultiple}
                                  options={optionsmultiCityConnection}
                                />
                              {/* <Select 
                                value={connetingMulti}
                              //   isSearchable ={true}
                              //   clearValue={setSelectedAirport}
                              placeholder ={"Connecting"}
                              //   onChange={handleChange}
                              //   isClearable={true}
                                onChange={handleChangesConntionMultiple}
                                options={optionsmultiCityConnection}
                              /> */}
                              </div>
                            
                            <div style={{display:"flex", flexDirection:"center", alignItems:"center",  flexWrap:"wrap"}}>

                                <TbArrowsLeftRight size={20}/>
                            </div>
                            <div style={{width:"40%"}}> 
                            <WindowedSelect                          
                                  value={destinationMulti}
                                  placeholder ={"Destination"}                         
                                  filterOption={customFilter}
                                  onChange={handleChangesDestinationMultiple}
                                  options={optionsmultiCityDestination}
                                />
                                {/* <Select 
                                  value={destinationMulti}
                                //   isSearchable ={true}
                                //   clearValue={setSelectedAirport}
                                placeholder ={"Destination"}
                                //   onChange={handleChange}
                                //   isClearable={true}
                                  onChange={handleChangesDestinationMultiple}
                                  options={optionsmultiCityDestination}
                                /> */}
                            </div>
                            {/* <IoAirplane size={20} style = {{transform: 'rotate(90deg)' }}/>  */}
                            
                </div>
            <span  >
                                {originMultiError && (<>
                                
                                  <BiErrorCircle size={20} style={{color:"darkred"}}/>  {originMultiError} <br />
                                </>)}
                                {connectingMultiError && (<>
                                
                                <BiErrorCircle size={20} style={{color:"darkred"}}/>  {connectingMultiError} <br />
                              </>)}
                                {destinationMultiError && (<>
                                
                                <BiErrorCircle size={20} style={{color:"darkred"}}/>  {destinationMultiError} <br />
                              </>)}
                            </span>
            </div>
            <br />
            <div style={{display:"flex", justifyContent:"center"}}>
                            <Button onClick = {()=>{submitMulti()}}style={{maxWidth:"20%"}}>Search</Button>
                            <br />
              </div>
        </div>
              <div style={{display:"flex", justifyContent:"center", paddingTop:"20px"}}>
                            {loading && <Spinner />}
              </div>
       { originAirport.name && originAirport.totalVotes === 0 && 
          <div 
            style={{
              boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
              borderRadius:"2px",
              padding:"15px",
              margin:"10px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:"column",
              gap:"2"
            }}
            > 
              <h2>Departure</h2>
              <BsEmojiFrown size={40} style={{margin:"5px", color:"#F6BE00"}}/> 
              Unfortunately no one has rated {originAirport.name} yet. <br />
              Please Rate the Airport, your Opinion is valulable to us to other travellers. <br /> 
               Be the First.
              
          </div>
       }


       
        
        { originAirport.name && originAirport.totalVotes !== 0 &&
            <div style={{
                  // width:"100%", 
                  boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
                  borderRadius:"2px",
                  padding:"15px",
                  margin:"10px",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  flexDirection:"column",
                  gap:"2",
                  // width:"50%"
                  
            }}>

                  <h2>
                  Departure 
                  </h2>
                  <br />
        <div style={{
            padding:"15px",
            backgroundColor:"#1AA7EC", 
            borderRadius:" 10px 100px / 120px",
            color:"white",
            display:"flex",
            flexGap:2,
            gap:5
        }}>
          
        <div>
    
            {originAirport.name} 
          </div>
          <div>
              <FaPlaneDeparture size={20} style={{float:"right"}}/>
          </div>  
          </div>
          <br />
          <h1>Experience</h1>
          {originAirport.Passed ===  originAirport.Fail && <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsEmojiNeutralFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
                <p>
                  {originAirport.totalVotes}  Travellers had an overall <u><b>neutral</b></u> experience
                </p>
            </div>}
             
              {originAirport.Passed >  originAirport.Fail && 
              <div>
                <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiSmileFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
              <p>
              Positive Experiences: {originAirport.Passed} <br />
                Travellers: {originAirport.totalVotes} 
            
              </p>
          </div>}
          {originAirport.Passed <  originAirport.Fail &&  <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiAngryFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
            <p>
            Negative Experiences: {originAirport.Fail} <br />
              Travellers: {originAirport.totalVotes} 
            </p>
              </div>}
              <b>Times</b>
              check-in To Plane:
              {" " +originAirport.timeD} <br />
              Plane to Exit:
              {" " + originAirport.timeA}
          
          </div>}

          { connectingAirport.name && connectingAirport.totalVotes === 0 && 
          <div  
            style={{
              boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
              borderRadius:"2px",
              padding:"15px",
              margin:"10px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:"column",
              gap:"2"
            }}
            > 
              <h2>Connecting</h2>
              <BsEmojiFrown size={40} style={{margin:"5px", color:"#F6BE00"}}/> 
              Unfortunately no one has rated {connectingAirport.name} yet. <br />
              Please Rate the Airport, your Opinion is valulable to us to other  Travellers. <br /> 
               Be the First.
              
          </div>
       }
 { connectingAirport.name && connectingAirport.totalVotes !== 0 &&
            <div style={{
                  // width:"100%", 
                  boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
                  borderRadius:"2px",
                  padding:"15px",
                  margin:"10px",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  flexDirection:"column",
                  gap:"2",
                  // width:"50%"
                  
            }}>

                  <h2>
                  Connecting Airport 
                  </h2>
                  <br />
        <div style={{
            padding:"15px",
            backgroundColor:"#1AA7EC", 
            borderRadius:" 10px 100px / 120px",
            color:"white",
            display:"flex",
            flexGap:2,
            gap:5
        }}>
          
        <div>
    
            {connectingAirport.name} 
          </div>
          <div>
              <FaPlaneDeparture size={20} style={{float:"right"}}/>
          </div>  
          </div>
          <br />
          <h1>Experience</h1>
          {connectingAirport.Passed ===  connectingAirport.Fail && <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsEmojiNeutralFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
                <p>
                  {connectingAirport.totalVotes}   Travellers had an overall <u><b>neutral</b></u> experience
                </p>
            </div>}
             
              {connectingAirport.Passed >  connectingAirport.Fail && 
              <div>
                <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiSmileFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
              <p>
              Positive Experiences: {connectingAirport.Passed} <br />
                Travellers: {connectingAirport.totalVotes} 
            
              </p>
          </div>}
          {connectingAirport.Passed <  connectingAirport.Fail &&  <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiAngryFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
            <p>
            Negative Experiences: {connectingAirport.Fail} <br />
              Travellers: {connectingAirport.totalVotes} 
            </p>
              </div>}
              <b>Times</b>
              check-in To Plane:
              {" " +connectingAirport.timeD}<br />
              Plane to Exit:
              {" " + connectingAirport.timeA}
          
          </div>}

       
          { destinationAirport.name && destinationAirport.totalVotes === 0 && 
          <div 
            style={{
              boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
              borderRadius:"2px",
              padding:"15px",
              margin:"10px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:"column",
              gap:"2"
            }}
            > 
              <h2>Arrival</h2>
              <BsEmojiFrown size={40} style={{margin:"5px", color:"#F6BE00"}}/> 
              Unfortunately no one has rated {destinationAirport.name} yet. <br />
              Please Rate the Airport, your Opinion is valulable to us to other travellers. <br /> 
               Be the First.
              
          </div>
       }
          { destinationAirport.name && destinationAirport.totalVotes !== 0 && 
          <div style={{
              // width:"100%", 
              boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
              borderRadius:"2px",
              paddingLeft:"2px",
              margin:"10px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:"column",
              gap:"1",
              // width:"50%"
              
        }}>
               <h2>
              Arrival 
              </h2>
               <br />
        <div style={{
          padding:"15px",
          backgroundColor:"#1AA7EC", 
          borderRadius:" 10px 100px / 120px",
          color:"white",
          display:"flex",
          flexGap:2,
          gap:5
        }}>
          
        <div>
    
            {destinationAirport.name} 
          </div>
          <div>
              <FaPlaneDeparture size={20} style={{float:"right"}}/>
          </div>  
          </div>
          <br />
          <h1>Experience</h1>
          {destinationAirport.Passed ===  destinationAirport.Fail && <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsEmojiNeutralFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
                <p>
                  {originAirport.totalVotes}  Travellers had an overall <u><b>neutral</b></u> experience
                </p>
            </div>}
             
              {destinationAirport.Passed >  destinationAirport.Fail && 
              <div>
                <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiSmileFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
              <p>
              Positive Experiences: {destinationAirport.Passed}  <br />
              Travellers: {destinationAirport.totalVotes} 
            
              </p>
          </div>}
          {destinationAirport.Passed <  destinationAirport.Fail &&  <div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiAngryFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>
            <p>
            Negative Experiences: {destinationAirport.Fail} <br />  
            Travellers: {destinationAirport.totalVotes} 
            </p>
              </div>}         
          <b>Times</b>
              check-in To Plane:
              {" " + destinationAirport.timeD}<br />
              Plane to Exit:
              {" " + destinationAirport.timeA}
          </div>}
          
    </div>
  )
}

export default SearchAirports