import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { BsFillEmojiAngryFill } from "react-icons/bs";
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError, createClient } from "@supabase/supabase-js";
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { BsEmojiNeutralFill } from "react-icons/bs";
import { FaPlaneDeparture } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Parking from './Parking';
import { AiFillStar } from "react-icons/ai";
import WindowedSelect from "react-windowed-select";

// Impulse Descision made to convert parking to staff and transit to connecting
// PLan of execution, have a button to toggle between stats and vote
const Airports = (props) => {
      const [passed, setPassed] = useState(false);
      const [fail, setFail] = useState(false);
      const [loading, setLoading] = useState(false);
      const [numbers, setNumbers] = useState([{index: 1, filled:"false"},{index: 2, filled:"false"},{index: 3, filled:"false"},{index: 4, filled:"false"},{index: 5, filled:"false"}]);
      const [parking, setParking] = useState([false, false, false, false, false, false, false])
      const [transit, setTransit] = useState([false, false, false, false, false, false, false])
      const [wifi, setWifi] = useState([false, false, false, false, false, false, false])
     const [baggage, setBaggage] = useState([false, false, false, false, false, false, false])
     const [showStats, setShowStats] = useState(false);
     const [parkingLoading, setParkingLoading] = useState(false);
     const [transitLoading, setTransitLoading] = useState(false);
     const [wifiLoading, setWifiLoading] = useState(false);
     const [baggageLoading, setBaggageLoading] = useState(false);
     const [showSuccess, setShowSuccess] = useState("");
     const [timeToDeparture, setTimeToDeparture] = useState({value: "3hrs", label:"3hrs"})
     const [timeToArrival, setTimeToArrival] = useState({value: "3hrs", label:"3hrs"})
     const [options, setOptions] = useState([
                                          {value: "3hrs", label:"3hrs"},    
                                        {value: "1hr", label:"less than or about 1hr"},
                                        {value: "2hr", label:"less than or about 2hr"},
                                        {value: "5hr", label:"more than or about 5hr"},
                                        {value: "12hr", label:"more than 12hr"},
                
                                      ])
      useEffect(()=>{
        updateSOmething("timeD")  


      },[timeToDeparture])

      useEffect(()=>{
        updateSOmething("timeA")  
      },[timeToArrival])

    const passAirport = async(e) =>{      
        e.preventDefault();
        setLoading(true);
        setPassed(true);
        setFail(false);
        const supabaseUrl = process.env.REACT_APP_URL
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
        const supabase = createClient(supabaseUrl, supabaseKey)
       
   
          const {data, error} = await supabase.from('Airports')
          .select()
          .eq("id", props.airport.id)
          .single()
          if(error){
            console.log(error)
          
          }
          if(data){          
            const {success, error} = await supabase.from('Airports')
            .update({Passed: data.Passed + 1, totalVotes: data.totalVotes + 1})
            .eq('id', props.airport.id)
            .select()
            setLoading(false);
            // props.fetchAirports();
          if(success){
            console.log(success)
          }
          
        }
        

    
       
       
      //   const { data, error } = await supabase.functions.invoke("addFailed", {
      //     body: JSON.stringify({id: 1}),
      //   })
      //   if (error) alert(error)
      //  console.log(data)
      

        // const { data, error } = await supabase.functions.invoke('addFailed', {
        //   headers: { "Content-Type": "application/json",
        // 'Access-Control-Allow-Origin':"*",
        // 'Allow-Control-Allow-Headers': 'authorization, x-client-info, apiKey'
        
        // },
        //   body: JSON.stringify({ id: props.airport.id })
        // })
        
        // if (error instanceof FunctionsHttpError) {
        //   console.log('Function returned an error', error.message)
        // } else if (error instanceof FunctionsRelayError) {
        //   console.log('Relay error:', error.message)
        // } else if (error instanceof FunctionsFetchError) {
        //   console.log('Fetch error:', error.message)
        // }

        // const {data, error} = await supabase
        // .from('AirpoAirportsrts')
        // .update({Passed:   +1})
        // .eq('id', props.airport.id)
        // .select( )

        // if(error){
        //     console.log(error)
        // }
        // if(data){
        //     console.log(data)
        // }
    }

    const updateSOmething = async(UpdateColumn) =>{
  
      const supabaseUrl = process.env.REACT_APP_URL
      const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
      const supabase = createClient(supabaseUrl, supabaseKey)
     
 
        const {data, error} = await supabase.from('Airports')
        .select()
        .eq("id", props.airport.id)
        .single()
        if(error){
          console.log(error)
        
        }
        
        if(data){
          if(UpdateColumn === "parking1")  {
              const {success, error} = await supabase.from('Airports')
              .update({parking1: data.parking1 + 1, parkingTotal: data.parkingTotal + 1})
              .eq('id', props.airport.id)
              .select()
          
              // props.fetchAirports();
              setParkingLoading(false);
            if(success){
              console.log(success)
            }

            if(error){
              setParkingLoading(false)
            }
          }    

          if(UpdateColumn === "parking2")  {
            const {success, error} = await supabase.from('Airports')
            .update({parking2: data.parking2 + 1, parkingTotal: data.parkingTotal + 1})
            .eq('id', props.airport.id)
            .select()
        
            // props.fetchAirports();
            // setParking(false)
            setParkingLoading(false)
          if(success){
            console.log(success)
          }
          if(error){
            setParkingLoading(false)
          }
        }   
        
        
        if(UpdateColumn === "parking3")  {
          const {success, error} = await supabase.from('Airports')
          .update({parking3: data.parking3 + 1, parkingTotal: data.parkingTotal + 1})
          .eq('id', props.airport.id)
          .select()
      
          // props.fetchAirports();
          setParkingLoading(false)
        if(success){
          console.log(success)
        }
        if(error){
          setParkingLoading(false)
        }
      }   

      if(UpdateColumn === "parking4")  {
        const {success, error} = await supabase.from('Airports')
        .update({parking4: data.parking3 + 1, parkingTotal: data.parkingTotal + 1})
        .eq('id', props.airport.id)
        .select()
    
        // props.fetchAirports();
        setParkingLoading(false)
      if(success){
        console.log(success)
      }
      if(error){
        setParkingLoading(false)
      }
    }   

    if(UpdateColumn === "parking5")  {
      const {success, error} = await supabase.from('Airports')
      .update({parking5: data.parking5 + 1, parkingTotal: data.parkingTotal + 1})
      .eq('id', props.airport.id)
      .select()
  
      // props.fetchAirports();
      setParkingLoading(false)
      // setLoading(false);
    if(success){
      console.log(success)
    }
    if(error){
      setParkingLoading(false)
    }
  }   

  if(UpdateColumn === "transit1")  {
    const {success, error} = await supabase.from('Airports')
    .update({transit1: data.transit1 + 1, transitTotal: data.transitTotal + 1})
    .eq('id', props.airport.id)
    .select()

    // props.fetchAirports();
    setTransitLoading(false);
   
  if(success){
    console.log(success)
  }

  if(error){
    setTransitLoading(false);

  }
} 

if(UpdateColumn === "transit2")  {
  const {success, error} = await supabase.from('Airports')
  .update({transit2: data.transit2 + 1, transitTotal: data.transitTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setTransitLoading(false);
  
if(success){
  console.log(success)
}
if(error){
  setTransitLoading(false);

}
} 
if(UpdateColumn === "transit3")  {
  const {success, error} = await supabase.from('Airports')
  .update({transit3: data.transit3 + 1, transitTotal: data.transitTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setTransitLoading(false);
if(success){
  console.log(success)
}
if(error){
  setTransitLoading(false);

}
} 

  if(UpdateColumn === "transit4")  {
    const {success, error} = await supabase.from('Airports')
    .update({transit4: data.transit4 + 1, transitTotal: data.transitTotal + 1})
    .eq('id', props.airport.id)
    .select()

    // props.fetchAirports();
    setTransitLoading(false);
  if(success){
    console.log(success)
  }
  if(error){
    setTransitLoading(false);

  }
  }
  
      if(UpdateColumn === "transit5")  {
        const {success, error} = await supabase.from('Airports')
        .update({transit5: data.transit5 + 1, transitTotal: data.transitTotal + 1})
        .eq('id', props.airport.id)
        .select()

        // props.fetchAirports();
        setTransitLoading(false);
      if(success){
        console.log(success)
      }
      if(error){
        setTransitLoading(false);

      }
      } 

if(UpdateColumn === "wifi1")  {
  const {success, error} = await supabase.from('Airports')
  .update({wifi1: data.wifi1 + 1, wifiTotal: data.wifiTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setWifiLoading(false)

if(success){
  console.log(success)
}
if(error){
  setWifiLoading(false)

}
} 

if(UpdateColumn === "wifi2")  {
  const {success, error} = await supabase.from('Airports')
  .update({wifi2: data.wifi2 + 1, wifiTotal: data.wifiTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setWifiLoading(false)
if(success){
  console.log(success)
}
if(error){
  setWifiLoading(false)

}
} 

if(UpdateColumn === "wifi3")  {
  const {success, error} = await supabase.from('Airports')
  .update({wifi3: data.wifi3 + 1, wifiTotal: data.wifiTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setWifiLoading(false)
if(success){
  console.log(success)
}
if(error){
  setWifiLoading(false)
}
} 

if(UpdateColumn === "wifi4")  {
  const {success, error} = await supabase.from('Airports')
  .update({wifi4: data.wifi4 + 1, wifiTotal: data.wifiTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setWifiLoading(false)
if(success){
  console.log(success)
}

if(error){
  setWifiLoading(false)

}
} 


    if(UpdateColumn === "wifi5")  {
      const {success, error} = await supabase.from('Airports')
      .update({wifi5: data.wifi5 + 1, wifiTotal: data.wifiTotal + 1})
      .eq('id', props.airport.id)
      .select()

      // props.fetchAirports();
      setWifiLoading(false)
    if(success){
      console.log(success)
    }

    if(error){
      setWifiLoading(false)

    }
    } 


if(UpdateColumn === "baggage1")  {
  
  const {success, error} = await supabase.from('Airports')
  .update({baggageClaim1: data.baggageClaim1 + 1, baggageClaimTotal: data.baggageClaimTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}

if(error){
  setBaggageLoading(false);

}
} 

if(UpdateColumn === "baggage2")  {
  const {success, error} = await supabase.from('Airports')
  .update({baggageClaim2: data.baggageClaim2 + 1, baggageClaimTotal: data.baggageClaimTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}
if(error){
  setBaggageLoading(false);

}
} 


if(UpdateColumn === "baggage3")  {
  const {success, error} = await supabase.from('Airports')
  .update({baggageClaim3: data.baggageClaim3 + 1, baggageClaimTotal: data.baggageClaimTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
 
if(success){
  console.log(success)
}
if(error){
  setBaggageLoading(false);

}
} 

if(UpdateColumn === "baggage4")  {
  const {success, error} = await supabase.from('Airports')
  .update({baggageClaim4: data.baggageClaim4 + 1, baggageClaimTotal: data.baggageClaimTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}
} 

if(UpdateColumn === "baggage5")  {
  const {success, error} = await supabase.from('Airports')
  .update({baggageClaim5: data.baggageClaim5 + 1, baggageClaimTotal: data.baggageClaimTotal + 1})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}
} 

if(UpdateColumn === "timeD")  {
  console.log(timeToDeparture)
  const {success, error} = await supabase.from('Airports')
  .update({timeD: timeToDeparture.value})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}
} 

if(UpdateColumn === "timeA")  {
  const {success, error} = await supabase.from('Airports')
  .update({timeA: timeToArrival.value})
  .eq('id', props.airport.id)
  .select()

  // props.fetchAirports();
  setBaggageLoading(false);
if(success){
  console.log(success)
}
}

if(error){
  setBaggageLoading(false);

}
      }
      
  
    }


    const wifiRating = (index) =>{
      setWifiLoading(true)
      let tempWifi = wifi;
      let finalWifi =tempWifi.map((el, i)=>{
          if(i !== parseInt(index)){  
                  if(i < index){
                    el = true;

                  } else {
                    el = false;
                  }
              
          } else {
             el = true;
           
          }
          return el;
      });
      setWifi(finalWifi);

      switch(index){
        case 0:
         
          updateSOmething("wifi1")        
          break;

        case 1:
            updateSOmething("wifi2");
            break;          
        case 2:
              updateSOmething("wifi3");
              break;
        case 3:
              updateSOmething("wifi4");
              break;
        case 4:
                updateSOmething("wifi5");
                break;

        
       
      }

    }


    const handleChange = (value)=>{
      console.log(value)
 
      setTimeToDeparture(value)
      

    }

    const handleChangeArrival = (value)=>{
  
      setTimeToArrival(value);
      

    }

    const transitRating = (index) =>{
      setTransitLoading(true);
      let tempTransit = transit;
      let finalTransit =tempTransit.map((el, i)=>{
          if(i !== parseInt(index)){  
                  if(i < index){
                    el = true;
                  } else {
                    el = false;
                  }
              
          } else {
             el = true;
           
          }
          return el;
      });
      setTransit(finalTransit);

      switch(index){
        case 0:
          updateSOmething("transit1")        
          break;

        case 1:
            updateSOmething("transit2");
            break;          
        case 2:
              updateSOmething("transit3");
              break;
        case 3:
              updateSOmething("transit4");
              break;
        case 4:
              updateSOmething("transit5");
              break;

        
      }

    }

    const parkingRating = (index) =>{
      setParkingLoading(true);
      let tempParking = parking;
      let finalParking =tempParking.map((el, i)=>{
          if(i !== parseInt(index)){  
                 if(i < index){
                  el = true
                 } else {
                   el = false;
                 }
              
          } else {
             el = true;
           
          }
          return el;
      });
   
      setParking(finalParking);

      switch(index){
        case 0:
          
          updateSOmething("parking1")        
          break;

        case 1:
            updateSOmething("parking2");
            break;          
        case 2:
              updateSOmething("parking3");
              break;
        case 3:
              updateSOmething("parking4");
              break;
        case 4:
              updateSOmething("parking5");
              break;
          
      }

    }


    const baggageRating = (index) =>{
      let tempBagge = baggage;
      setBaggageLoading(true)
      let finalBaggage =tempBagge.map((el, i)=>{
          if(i !== parseInt(index)){  
                 if(i < index){
                  el = true;
                 } else {
                   el = false;
                 }
              
          } else {
             el = true;
           
          }
          return el;
      });
      setBaggage(finalBaggage);

      switch(index){
        case 0:
          updateSOmething("baggage1")        
          break;

        case 1:
            updateSOmething("baggage2");
            break;          
        case 2:
              updateSOmething("baggage3");
              break;
        case 3:
              updateSOmething("baggage4");
              break;
        case 4:
              updateSOmething("baggage5");
              break;
      }

    }

    const failAirport = async(e) =>{
      e.preventDefault();
      setLoading(true);
      setFail(true);
      setPassed(false);
      const supabaseUrl = process.env.REACT_APP_URL
      const supabaseKey = process.env.REACT_APP_SUPABASE_KEY  
      const supabase = createClient(supabaseUrl, supabaseKey)
     
 
        const {data, error} = await supabase.from('Airports')
        .select()
        .eq("id", props.airport.id)
        .single()
        if(error){
          console.log(error)
        
        }
        if(data){          
          const {success, error} = await supabase.from('Airports')
          .update({Fail: data.Fail + 1, totalVotes: data.totalVotes + 1})
          .eq('id', props.airport.id)
          .select()
       
          // props.fetchAirports();
          setLoading(false);
        if(success){
          console.log(success)
        }
        
      }
      
  
     
     
    //   const { data, error } = await supabase.functions.invoke("addFailed", {
    //     body: JSON.stringify({id: 1}),
    //   })
    //   if (error) alert(error)
    //  console.log(data)
    

      // const { data, error } = await supabase.functions.invoke('addFailed', {
      //   headers: { "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin':"*",
      // 'Allow-Control-Allow-Headers': 'authorization, x-client-info, apiKey'
      
      // },
      //   body: JSON.stringify({ id: props.airport.id })
      // })
      
      // if (error instanceof FunctionsHttpError) {
      //   console.log('Function returned an error', error.message)
      // } else if (error instanceof FunctionsRelayError) {
      //   console.log('Relay error:', error.message)
      // } else if (error instanceof FunctionsFetchError) {
      //   console.log('Fetch error:', error.message)
      // }

      // const {data, error} = await supabase
      // .from('AirpoAirportsrts')
      // .update({Passed:   +1})
      // .eq('id', props.airport.id)
      // .select( )

      // if(error){
      //     console.log(error)
      // }
      // if(data){
      //     console.log(data)
      // }
  }
  return (
  <div style={{
        // width:"100%", 
        boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
        borderRadius:"2px",
        padding:"15px",
        margin:"10px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        gap:"2"
  }}>
    <div style={{
      padding:"15px",
      backgroundColor:"#1AA7EC", 
      borderRadius:" 10px 100px / 120px",
      color:"white",
      display:"flex",
      flexGap:2,
      gap:5
     }}>
          <div>
            {props.airport.name}
          </div>
          <div>
              <FaPlaneDeparture size={20} style={{float:"right"}}/>
          </div>               
    </div>
    {}
        {showStats && props.airport.totalVotes !== 0 && props.airport.Passed > props.airport.Fail &&
        <BsFillEmojiSmileFill  size={70} style={{margin:"5px", color:"#F6BE00"}}/>    }    
        {showStats && props.airport.totalVotes !== 0 && props.airport.Fail > props.airport.Passed && 
        < BsFillEmojiAngryFill style={{margin:"5px", color:"#F6BE00"}} size={70}/> }
       {showStats && props.airport.totalVotes !== 0 && props.airporttotalVotes !== 0 && props.airport.Passed === props.airport.Fail && <
        BsEmojiNeutralFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>} 
        {showStats && (<div> ({props.airport.totalVotes})  </div>)}
      
        <br />
    {!showStats && <div style={{margin:"2px", textAlign:"center"}}>
      <b style={{color:"#042b60", fontSize:"1.5em"}}>
          How Was Your Experience?
      </b>
    
    <br />
    {loading  ?  <Spinner animation="border" variant="primary" /> : <div>
       < BsFillEmojiAngryFill size={50} style={{margin:"5px", color: fail ? "#F6BE00" : "gray"}} onClick={(e)=>failAirport(e)}/> 
       
            or 
       
       <BsFillEmojiSmileFill  size={50} style={{margin:"5px", color: passed ? "#F6BE00" : "gray"}} onClick={(e)=>passAirport(e)}/>
    <br />
    </div>}
    
    
    </div>}
    <br />

    <div> 
            <div> 
              <div style={{display:"flex", textAlign:"center", flexDirection:"column"}}>
                <div style={{
                    display:"flex",
                  alignItems:"stretch",
                  fontSize:"1em",
                  justifyContent:"space-between"
                }}>

                      {/* <p style={{float:"left"}}>Poor</p> */}
                      {/* <p style={{float:"right", textAlign:"right"}}>Good</p> */}
                </div>
                <h3 style={{fontSize:"1em"}}> Handling Unexpected Weather</h3>
                <div>
                  {parkingLoading ? <Spinner animation="border" variant="primary" /> : numbers.map((el, i)=>(
                  
                  <AiFillStar 
                    size={30}
                    // variant= {parking[i] ? "primary" :"outline-primary" }
                    style={{
                      margin:"2px",
                      color: parking[i] ? "#FDCC0D" : ""                   
                    }}
                    value={i}
                    onClick={(e)=>parkingRating(i)}
                  />
                    
                  // </Button>
                  
                  ))} <br />
                </div>
              </div>
              <div style={{display:"flex", textAlign:"center", marginTop:"5px", flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}>Connections</h3>
                <div>
                  {transitLoading ? <Spinner animation="border" variant="primary" /> : 
                  numbers.map((el, i)=>(
                     <AiFillStar 
                        size={30}
                        //  variant= {parking[i] ? "primary" :"outline-primary" }
                        style={{
                          margin:"2px",
                          color: transit[i] ? "#FDCC0D" : ""                      
                        }}
                        value={i}
                        onClick={(e)=>transitRating(i)}
                   />
                  // <Button 
                  //   value={i}
                  //   onClick={(e)=>{transitRating(e.target.value, e)}}
                  //   variant= {transit[i] ? "primary" :"outline-primary" }
                  //   style={{
                  //       margin:"2px", 
                  //       // backgroundColor:"#042b60",
                  //       // color:"white"
                  //     }}
                      
                  //      >{el}
                  //      </Button>
                       ))} <br />
                </div>
              </div>
              {/* <div style={{display:"flex", marginTop:"5px", textAlign:"center", flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}>Passenger Pick up/Drop off</h3>
                <div>
                  {numbers.map((el)=>(<Button variant="outline-primary" style={{margin:"2px",  backgroundColor:"#042b60",color:"white"}}>{el}</Button>))} <br />
                </div>
              </div> */}

              <div style={{display:"flex", textAlign:"center", marginTop:"5px",flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}> Wifi</h3>
                <div>
                  { wifiLoading ? <Spinner animation="border" variant="primary" /> : numbers.map((el, i)=>(
                     <AiFillStar 
                     size={30}
                    //  variant= {parking[i] ? "primary" :"outline-primary" }
                     style={{
                       margin:"2px", 
                       color: wifi[i] ? "#FDCC0D" : ""                    
                     }}
                     value={i}
                     onClick={(e)=>wifiRating(i)}
                   />
                  //   <Button 
                  //     onClick={(e)=>{wifiRating(e.target.value)}}
                  //     variant= {wifi[i] ? "primary" :"outline-primary" }
                  //     value={i}
                  //     style={{margin:"2px",  
                  //     // backgroundColor:"#042b60",
                  //     // color:"white"
                  //   }}
                  // >{el}
                  // </Button>
                  ))} <br />
                </div>
              </div>

              <div style={{display:"flex",marginTop:"5px", textAlign:"center", flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}> Baggage Claim</h3>
                <div>
                  {baggageLoading ? <Spinner animation="border" variant="primary" /> : numbers.map((el, i)=>(
                 <AiFillStar 
                    size={30}
                    
                    style={{
                      margin:"2px",
                      color: baggage[i] ? "#FDCC0D" : ""                     
                    }}
                    value={i}
                    onClick={(e)=>baggageRating(i)}
                  />  
                  // <Button 
                  
                  // onClick={(e)=>{baggageRating(e.target.value, e)}}
                  //   variant= {baggage[i] ? "primary" :"outline-primary" }
                  //   style={{
                  //       margin:"2px", 
                  //       // backgroundColor:"#042b60",
                  //       // color:"white"
                  //     }}
                  //     value={i}
                  //     >
                  //   {el}
                  //   </Button>
                    ))} <br />
                <h3 style={{fontSize:"1em"}}> Times</h3> 
                
                <h3 style={{fontSize:"1em"}}> Check-In to plane</h3> 


               <WindowedSelect
                  options={options}
                  onChange={(e)=>handleChange(e)}
                  value={timeToDeparture}
                  placeholder ={"Check-In to plane"}
                  // isClearable={handleChange}
                  // filterOption={customFilter}
                />
            <br />
            <h3 style={{fontSize:"1em"}}> Plane To Exit</h3> 
            <WindowedSelect
                  options={options}
                  onChange={(e)=>handleChangeArrival(e)}
                  value={timeToArrival}
                  isSearchable={false}
                  placeholder ={"Plane To Exit"}
                  // isClearable={handleChange}
                  // filterOption={customFilter}
                /><br />
                </div>
                {/* <Button onClick={(e)=>{setShowStats(!showStats)}}> Current Stats</Button> */}
              <Button onClick={()=> {
                window.location.reload();
                setShowSuccess("Thank you For your Input.");
                
                }}>Submit</Button>
                {showSuccess && <Spinner />}
              </div>
                
{/* 
              <div style={{display:"flex",marginTop:"5px", textAlign:"center", flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}> Staff</h3>
                <div>
                  {numbers.map((el)=>(<Button variant="outline-primary" style={{margin:"2px",  backgroundColor:"#042b60",color:"white"}}>{el}</Button>))} <br />
                </div>
              </div> */}
              {/* <div style={{display:"flex",marginTop:"5px", textAlign:"center", flexDirection:"column"}}>
                <h3 style={{fontSize:"1em"}}>Food Options</h3>
                <div>
                  {numbers.map((el)=>(<Button variant="outline-primary"  style={{margin:"2px",  backgroundColor:"#042b60",color:"white"}}>{el}</Button>))} <br />
                </div>
              </div> */}
            </div>
        <div>

        </div>

    </div>
  </div>
    // <Accordion.Item eventKey={props.eventKey.toString()}>
    //   <Accordion.Header style={{backgroundColor:"black", }}>{props.airport.name} ({props.airport.totalVotes})</Accordion.Header>
    //   <Accordion.Body>
    //    Ease of Connection: {props.airport.EaseOfConnection} <br />
    //    Baggage Wait Time: {props.airport.BaggageWaitTime} <br />
    //    Finding Gates: {props.airport.FindingGates} <br />
    //    Shopping: {props.airport.Shopping} <br />
    //    Food: {props.airport.Food} <br />
    //    Lounges: {props.airport.Lounges}<br />
    //    Seats Comfort: {props.airport.SeatsComfort} <br />
    //    Will TravelAgain: {props.airport.WillTravelAgain} <br />

    //   </Accordion.Body>
    // </Accordion.Item>

  
    // <div>{props.airport.name}</div>
  )
}

export default Airports