import React from 'react'
import {BsFacebook} from "react-icons/bs"

const About = () => {
  return (
    <div>
    <div style={{display:"flex", alignContent:"center", justifyContent:"center"}}>
        <h1>About us</h1>
    <br />
    </div>
    <div>
            <p>
                My Aim with this website is to create a space, where travellers can rate airports, helping other travellers,
                to know what to expect. <br />
                
            </p>
    </div>
        <div>
            <h3>Future</h3>
            <ul>
                <li> I am going to show a star rating for Each Airport, (currently working on it)</li>
                <li>Plane Spotting Area for Enthusiast rating</li>
                <li>A Page For Rating Boeing Vs Airbus</li>
                <li>Add a tik tok or youtube link of your experience</li>
                <li>Make this project open source, other developers can work on it as well</li>
            </ul>
        
        </div>
        <div>
            <h3>Who am I</h3>
            I am an Aviation Enthusiast and a software developer. I had this idea, when I saw
            the customer service, or lack of service at some major airports. However these airports are considered
            the best in the world with multiple awards. I realized travellers need a more transparent resource to be better aware
            of what they are going to be subjected too. 
            
        </div>
        <div>
            <h3>Contact Me</h3> 
            <BsFacebook />Send me a message on facebook  <a href="https://www.facebook.com/people/Rate-My-airport/100088698383322/">Rate my Airport Facebook Page</a>
        </div>
    </div>
  )
}

export default About