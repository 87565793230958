import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import HomePage from "./Components/Pages/HomePage";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import AirportStats from './Components/RatePage.js/AirportStats';
import RatePage from './Components/Pages/RatePage';
import ViewTripPage from './Components/Pages/ViewTripPage';
import AboutPage from './Components/Pages/AboutPage';
import Privacy from './Components/PrivacyPage/Privacy';

function App() {
  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/"
            element={ <HomePage />} 
            />

        <Route path="/rate"
            element={ <RatePage />} 
            />
        <Route path="/viewtrip" 
          element={ <ViewTripPage />}
        />
             <Route path="/aboutus" 
          element={ <AboutPage />}
        />
          <Route path="/privacy" 
          element={ <Privacy />}
        />
      </Routes>
      </BrowserRouter>
     
    
  );
}

export default App;
