import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from '../HomePage/Jumbotron';
import Header from "../../Header";
import RateMyAirport from '../HomePage/RateMyAirport';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


const HomePage = () => {
  return (
    <>
     <Helmet >
      <title>Rate Airports</title>
      <link rel="notImportant" href="https://ratemyairport.ca/" />
      <meta name="description"   content="Rate Airports, plan a trip and see what to expect on your travel." />
      <link rel="canonical" href="https://ratemyairport.ca/" />
      <meta property="og:title" content="rate Airports,"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://ratemyairport.ca/"/>
     
    </Helmet>  
    <Container 
      fluid 
      style={{    
        // background:"linear-gradient( white, #b8e2dd)",
        // backgroundColor: "#00B5E2",
        backgroundColor: "white",

        height:"100vh",
      }} 
     > 
     <Row>
        <Header />
     </Row>
      <Row>
          <Jumbotron />
      </Row>
      <Row>
        <br />
        <RateMyAirport />
      </Row>
      <br />

    </Container>
    
    </>
  )
}

export default HomePage