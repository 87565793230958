import React from 'react'
import {FaPlaneDeparture} from 'react-icons/fa';
import {useState, useEffect} from 'react'
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { BsFillEmojiAngryFill } from "react-icons/bs";
import { BsEmojiNeutralFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

const CurrentStats = (props) => {
    const [positive, setPositive] = useState(false);
    const [neutral, setNeutral] = useState(false);
    const [negative, setNegative] = useState(false);
    const [staff, setStaff] = useState([]);
    const [nofillStaff, setnoFillStaff] = useState([]);

    useEffect(()=>{
        // console.log(props.airport)
        if(props.airport.Passed > props.airport.Fail){
            setPositive(true)
        }

        if(props.airport.Passed < props.airport.Fail){
            setNegative(true)
        }

        if(props.airport.Passed === props.airport.Fail){
            setNeutral(true)
        }

        // let tempStaff = []
      
        //     for(let i = 0; i <a[0].index + 1 ;i++){
        //             tempStaff.push(i)
        //     }
        //     setStaff(tempStaff)
         

    })

    const staffConnection = () =>{
       
                    let staff = [
                        {
                            number: props.airport.parking1, 
                            index: 1
                        }, 
                        {
                            number: props.airport.parking2,
                                index: 2
                        },
                        {
                            number: props.airport.parking3,
                                index: 3
                        },
                        {
                            number: props.airport.parking4,
                                index: 2
                        } ]
                let a = staff.sort((a, b)=> a.number > b.number ?  1 : -1)
                let standard5 = [0,1,2,3,4]
                let returnStaff =[]
                console.log(a[0].index)
                console.log(a[0].number)
                for(let i = 1; i< 6 ; i++){
                    if(i <= a[0].index){
                        console.log(i)
                        if(a[0].number === 0){
                                console.log("unfill")
                        } else {
                            console.log("fill")
                        }
                    } else {
                        console.log("unfill")
                    }
                }
                

              
    }
  return (
            <div style={{
                width:"100%", 
                boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
                borderRadius:"2px",
                padding:"15px",
                margin:"10px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column",
                gap:"2"
        }}>
            <div style={{
            padding:"15px",
            backgroundColor:"#1AA7EC", 
            borderRadius:" 10px 100px / 120px",
            color:"white",
            display:"flex",
            justifyContent:"space-evenly",
            textAlign:"center",
            flexGap:2,
            gap:5,
            // width:"100%"
            }}>
                <div>
                    {props.airport.name}
                </div>
                <div>
                    <FaPlaneDeparture size={20} style={{float:"right"}}/>
                </div>               
            </div>
           {positive && (<div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiSmileFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>)
                }

            {neutral && (<div style={{display:"flex", justifyContent:"center"}}>
                <BsEmojiNeutralFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>)
                }
            {negative && (<div style={{display:"flex", justifyContent:"center"}}>
                <BsFillEmojiAngryFill size={70} style={{margin:"5px", color:"#F6BE00"}}/>
                </div>)
                }
                ({props.airport.totalVotes})

                <div style={{display:"flex", flexDirection: "row"}}>
                    {/* {staffConnection()} */}
                {/* {staff.length > 0 && staff[0].number !== 0 && staff.map((el)=> (
                    <AiFillStar 
                        size={30}
                        // variant= {parking[i] ? "primary" :"outline-primary" }
                        style={{
                        margin:"2px",
                        color:"#FDCC0D"                 
                        }}
                    
                    /> 
                    ))} */}
                    </div>
                   <h3>Times</h3> 
                   Check-in To Plane:   {" " + props.airport.timeD} <br />
                   Plane to Exit:  {" " + props.airport.timeA}
                 
        <div style={{display:"flex"}}>
        {staff.length > 0 &&
         staff[0].number === 0 && staff.map((el)=> (

                    <AiFillStar 
                        size={30}
                        // variant= {parking[i] ? "primary" :"outline-primary" }
                        style={{
                        margin:"2px",
                        color:"gray"                 
                        }}
                    
                    />
                    ))}
                   
                    </div>
            
 </div>
  )
}

export default CurrentStats
