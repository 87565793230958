import React from 'react';
import Header from '../../Header';
import Container from 'react-bootstrap/Container';
import AirportStats from '../RatePage.js/AirportStats';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import About from "../AboutUsPage/About"
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const AboutPage = () => {
  return (
    <>
     <Helmet >
      <title>About us Airports</title>
      <link rel="notImportant" href="https://ratemyairport.ca/aboutus/" />
      <meta name="description"   content="Rate Airports, plan a trip and see what to expect on your travel." />
      <link rel="canonical" href="https://ratemyairport.ca/aboutus/" />
      <meta property="og:title" content="about"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://ratemyairport.ca/aboutus/"/>
     
    </Helmet> 
    <Header />
    <Container  style={{
        
        // backgroundColor:"#e1f7fd"
        }}>
        <Row>
        </Row>
        <Row>
            <About />
        </Row>
    
    </Container>
    </>
  )
}

export default AboutPage