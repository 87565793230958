import React from 'react';
import World from "../../assets/images/world.mp4"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Pic from "../../assets/images/airport.png"
const Jumbotron = () => {
  let navigate= useNavigate()
  return (
   
    <Container 
          fluid 
          style={{ 
            display:"flex", 
            alignItems:"center", 
            justifyContent:"center",
            paddingTop:"20px",
            // height:"80vh"
          }}
        >     
        <Row   style={{backgroundColor:"#00B5E2"}}>
             <Col xs={12} md={6} 
           >
                <div style={{
                  display:"flex",
                  justifyContent:"center", 
                  flexDirection:"column",
                  alignItems:"center",
                  width:"100vh",
                 textAlign:"center",
                 paddingTop:"70px"
                  }}
                 >
                  <div>

                    <h1 style={{ fontSize:"4.5em", 
                                fontWeight:"bolder",
                                color:"white",
                                display:"flex",
                                alignItems:"center",
                                marginLeft:"10px",
                                
                                  }}
                                  >
                          Rate <br />
                          Your <br />
                          Airport <br />
                          Experience <br />
                      </h1> 
                  </div>
                  <Button style={{
                      float:"right",
                      backgroundColor:"white",
                      margin:"10px",
                      color:"#00B5E2"

                    }}
                    onClick={(e)=>{ navigate("/rate");}}
                    size="md"
                      >
                    Start here
                    </Button>
                </div>
             </Col>
           
            <Col xs={12} md={6}  style={{backgroundColor:"#e1f7fd", display:"flex", alignContent:"center", justifyContent:"center"}}>
            {/* <img src={Luggage} /> */}
                <video  
                 
                  autoPlay  
                  muted 
                  loop={true} 
                  src={World} 
                  poster={Pic}
                  type="video/mp4" 
                  />
            </Col> 
        </Row>
    </Container>
  
  )
}

export default Jumbotron